import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import { SafeImage } from "../common";

const Container = styled.div`
  h2 {
    margin-bottom: 1rem !important;
    @media ${props => props.theme.device.tablet} {
      margin-bottom: 3rem !important;
    }
  }

  .capabilities-flexible {
    display: flex;
    flex-direction: column;
    @media ${props => props.theme.device.laptop} {
      justify-content: space-between;
    }
    > * {
      height: 50%;
    }
  }
`;

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 6rem;

  @media ${props => props.theme.device.laptop} {
    flex-direction: row;
  }

  > div {
    @media ${props => props.theme.device.laptop} {
      width: 25%;
    }
  }

  h3 {
    margin-bottom: 1.6rem;
  }
`;

const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;

  @media ${props => props.theme.device.laptop} {
    flex-direction: column;
  }
`;

const CapabilityContainer = styled.div`
  display: flex;
  align-items: center;
  :last-of-type {
    margin-right: 0;
  }

  p {
    margin: 0;
    display: none;
    @media ${props => props.theme.device.laptop} {
      display: block;
    }
  }

  img {
    width: 3rem;
    height: 3rem;
    @media ${props => props.theme.device.tablet} {
      width: 4rem;
      height: 4rem;
    }
    margin-right: 1rem;
  }

  :hover {
    opacity: 0.7;
  }
`;

const Capability = ({ image, name }) => (
  <CapabilityContainer>
    <SafeImage alt={`${name} logo`} src={`tech-logos/${image}`} />
    <p>{name}</p>
  </CapabilityContainer>
);

Capability.propTypes = {
  image: PropTypes.any.isRequired,
  name: PropTypes.string.isRequired
};

const Capabilities = () => (
  <Container>
    <h2>Our capabilities</h2>
    <ListContainer>
      <div>
        <h3>Infrastructure</h3>
        <List>
          <Capability image={"cloud/docker.png"} name="Docker" />
          <Capability image={"cloud/kubernetes.png"} name="Kubernetes" />
          <Capability image={"cloud/prometheus.png"} name="Prometheus" />
          <Capability image={"cloud/kafka.png"} name="Kafka" />
          <Capability image={"cloud/rabbitmq.png"} name="RabbitMQ" />
        </List>
      </div>
      <div>
        <h3>Backend</h3>
        <List>
          <Capability image={"backend/node.png"} name="Node.js" />
          <Capability image={"backend/python.png"} name="Python" />
          <Capability image={"backend/rust.png"} name="Rust" />
          <Capability image={"backend/elixir.png"} name="Elixir" />
        </List>
      </div>
        <div>
          <h3>Frontend</h3>
          <List>
            <Capability image={"frontend/react.png"} name="React" />
            <Capability image={"frontend/angular.png"} name="Angular" />
            <Capability image={"frontend/vue.png"} name="Vue" />
          </List>
        </div>
        <div>
          <h3>Cloud</h3>
          <List>
            <Capability image={"vendors/aws.png"} name="AWS" />
            <Capability
              image={"vendors/google-cloud.png"}
              name="Google Cloud"
            />
            <Capability image={"vendors/azure.png"} name="Azure" />
            <Capability
              image={"vendors/digitalocean.png"}
              name="DigitalOcean"
            />
          </List>
        </div>        
    </ListContainer>
  </Container>
);

export default Capabilities;
