import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { Card, CardList, SafeImage } from "../common";

const HighlightCard = styled(Card)`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 1.5rem 1.5rem 2rem 1.5rem;
  @media ${props => props.theme.device.tablet} {
    padding: 2.5rem 2.5rem 3rem 2.5rem;
  }

  h3 {
    font-size: 1.25rem;
    letter-spacing: 0.0625rem;
    line-height: 1.875rem;
    min-height: 20%;
    margin: 0.6rem 0;
    @media ${props => props.theme.device.tablet} {
      margin: 1rem 0;
    }
  }

  .description {
    color: ${props => props.theme.color.border};
    letter-spacing: 0.03125rem;
    line-height: 1.5rem;
    margin-bottom: 3rem;
  }

  .profile-picture {
    width: 3rem;
    height: 3rem;
    border-radius: 1.5rem;
    margin-right: 0.5rem;
  }
`;

const Authors = styled.div`
  display: flex;
  flex-direction: row;
`;

const BlogHighlight = ({ articles }) => (
  <div className="full-width content-gray">
    <h2
      className="baseline-center-right no-top-spacing"
      id="case-studies-long-form-tutorials"
    >
      Case studies & Long-form tutorials
    </h2>
    <CardList
      steps={[
        { device: "laptopL", column: 3 },
        { device: "tablet", column: 2 }
      ]}
      fixedCards
    >
      {articles.map(({ href, title, description, authors }, i) => {
        return (
          <HighlightCard
            key={`tc_${i}`}
            href={`https://blog.risingstack.com/${href}`}
          >
            <Authors>
              {authors.map((author, i) => (
                <SafeImage
                  alt="author profile picture"
                  className="profile-picture"
                  key={`author_${i}`}
                  src={`team/blog/${author}_blog.jpg`}
                />
              ))}
            </Authors>
            <h3>{title}</h3>
            <p className="description">{description}</p>
            <p className="cta cta-button hideable">Read</p>
          </HighlightCard>
        );
      })}
    </CardList>
  </div>
);

BlogHighlight.propTypes = {
  articles: PropTypes.array
};

export default BlogHighlight;
