import React, { Component } from "react";
import styled from "styled-components";
import illustration from "../../assets/illustrations/cookie.svg";
// import PropTypes from "prop-types";

const Container = styled.div`
  position: fixed;
  display: flex;

  border: 1px solid ${({ theme }) => theme.color.border_light};
  background-color: #fff;
  box-shadow: 0 1rem 1.7rem rgba(0, 0, 0, 0.25),
    0 0.6rem 0.6rem rgba(0, 0, 0, 0.22);

  bottom: 0.5rem;
  left: 0.5rem;
  right: 0.5rem;
  padding: 1.25rem;
  flex-direction: column;
  z-index: 101;

  img {
    max-height: 3rem;
    margin: auto 0;
  }

  * {
    margin: auto 0;
    font-size: 16px;
  }

  .text-container {
    padding-left: 1rem;
    @media ${props => props.theme.device.tablet} {
      padding: 0 2rem;
    }
  }

  .content {
    display: flex;
    flex-direction: row;
  }

  button {
    --webkit-display: none;
    background-color: inherit;
    color: ${({ theme }) => theme.color.secondary};
    margin: 1rem 0 0 auto;
    @media ${props => props.theme.device.tablet} {
      margin: 0;
    }
  }

  @media ${props => props.theme.device.tablet} {
    bottom: 2rem;
    right: 2rem;
    left: auto;
    flex-direction: row;
  }
`;

class CookieConsent extends Component {
  addDays = days => {
    var date = new Date();
    date.setDate(date.getDate() + days);
    return date;
  };

  setCookie = (name, value) => {
    var expires = "";
    var date = this.addDays(365);
    expires = "; expires=" + date.toUTCString();
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  };

  render() {
    const { removeHandler, ...rest } = this.props;
    return (
      <Container {...rest}>
        <div className="content">
          <img alt="cookie consent illustration" src={illustration} />
          <div className="text-container">
            <p>This website uses cookies.</p>
            <a href="https://risingstack.com/privacy">Our cookie policy</a>
          </div>
        </div>
        <button
          onClick={e => {
            e.preventDefault();
            window.dataLayer.push({ event: "rs_cookie_consent_accepted" });
            this.setCookie("rs_cookie_consent_accepted", "true");
            removeHandler();
          }}
        >
          Accept
        </button>
      </Container>
    );
  }
}

CookieConsent.propTpyes = {};

CookieConsent.defaultProps = {};

export default CookieConsent;
