import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import { Card, CardList, SafeImage } from "../common";

const Container = styled(CardList)`
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  &.home {
    margin: 0;
    width: calc(100vw - 3rem);
    padding: 1.5rem 0;
    @media ${props => props.theme.device.tablet} {
      width: 90vw;
      padding: 2.5rem 0;
    }
    @media ${props => props.theme.device.laptop} {
      width: 80vw;
      padding: 2.5rem 0;
    }
  }
`;

const Training = styled(Card)`
  display: flex;
  background-color: #fff;
  align-items: center;
  height: 7rem;
  overflow: hidden;
  justify-content: space-between;

  img {
    height: 7rem;
    width: 7rem;
    border-top-left-radius: 0.3rem;
    border-bottom-left-radius: 0.3rem;
    margin: -0.0625rem;
    border-right: 0.0625rem solid ${props => props.theme.color.border_light};
  }

  p {
    font-size: 1rem;
    line-height: 1.875rem;
    margin: 0 auto 0 1.25rem;
    padding: 0 1.25rem 0 0;
    letter-spacing: 0.03125rem;
    line-height: 1.5rem;
    color: #000;
  }
`;

const TrainingCards = ({ trainings, variant }) => (
  <Container
    isUneven={trainings.length % 3 !== 0}
    className={`full-width fix ${variant}`}
  >
    {trainings.map(({ title, href, illustration }, i) => (
      <Training key={`tc_${i}`} href={href}>
        <SafeImage
          alt={`${title} illustration`}
          src={`illustrations/trainings/${illustration}`}
        />
        <p>{title}</p>
      </Training>
    ))}
  </Container>
);

TrainingCards.propTypes = {
  trainings: PropTypes.array,
  variant: PropTypes.string
};

export default TrainingCards;
