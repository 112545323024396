import React from "react";
import _ from "underscore";
import cheerio from "cheerio";
import ReactHtmlParser from "react-html-parser";

import {
  BlogHighlight,
  Capabilities,
  Clients,
  ContactForm,
  EbookDownload,
  EbookSimiliar,
  Jobs,
  OpenTrainings,
  Team,
  Testimonials,
  TrainingCards,
  TrainingLocations,
  WhatWeDo
} from "../components/meta";

export const processHtml = (html, md) => {
  // Split HTML file at dividers
  let splittedPlain = md.split(
    "--------------------------------------------------------------------------------"
  );
  let splittedHtml = html.split("<hr>");

  let metadata = {};
  let toc;
  let jumbotronData = {};

  // Table of contents generator
  if (!toc) {
    const $ = cheerio.load(html);
    toc = $("h2")
      .get()
      .map(({ attribs, children }) => {
        return {
          id: attribs.id,
          innerText: children[0].data
        };
      });
    0;
  }

  // Filter metacomponents
  // htmlX stands for HTML eXtended, the custom syntax of this generator
  const htmlX = splittedPlain
    .map((item, index) => {
      item = _.unescape(item);
      item = item.split("<span>://</span>").join("://");
      if (item.includes("%%%METAELEMENT")) {
        // Parses metaelement section's content to JSON
        const data = JSON.parse(
          strip(item.replace(/<br>|\n/g, " ").match(/{.*}/g, "")[0])
        );
        metadata[data.name] = data.props;
        if (data.name === "TOC") {
          toc = data.props;
          return null;
        } else if (data.name === "JumbotronData") {
          jumbotronData = data.props;
          return null;
        } else {
          const { element, tocItem } = metaTransformer(data);
          toc.splice(Math.abs(index - 1), 0, tocItem);
          return element;
        }
      }
      return ReactHtmlParser(splittedHtml[index]);
    })
    .filter(item => item != null);

  toc = toc.filter(item => item != null);

  return {
    splittedPlain,
    metadata,
    htmlX,
    toc,
    jumbotronData
  };
};

const strip = s => s.replace(/[\u200C]/g, "");

// Transform serialized JSON object into React component
const metaTransformer = item => {
  const variant = item.variant || "default";
  switch (item.name) {
    case "BlogHighlight":
      return {
        tocItem: {
          id: "case-studies-long-form-tutorials",
          innerText: "Case studies & Long-form tutorials"
        },
        element: <BlogHighlight variant={variant} articles={item.props} />
      };
    case "Capabilities":
      return {
        tocItem: null,
        element: <Capabilities />
      };
    case "Clients":
      return {
        tocItem: null,
        element: <Clients clients={item.props} />
      };
    case "ContactForm":
      return {
        tocItem: null,
        element: <ContactForm variant={variant} {...item.props} />
      };
    case "EbookDownload":
      return {
        tocItem: null,
        element: <EbookDownload urlParams={item.props} />
      };
    case "EbookSimiliar":
      return {
        tocItem: { id: "similiar-ebooks", innerText: "Similiar eBooks" },
        element: <EbookSimiliar variant={variant} books={item.props} />
      };
    case "Jobs":
      return {
        tocItem: { id: "open-positions", innerText: "Open positions" },
        element: <Jobs jobs={item.props} />
      };
    case "OpenTrainings":
      return {
        tocItem: null,
        element: <OpenTrainings variant={variant} trainings={item.props} />
      };
    case "Team":
      return {
        tocItem: {
          id: "meet-our-instructors",
          innerText: "Meet our instructors"
        },
        element: (
          <Team
            heading="Meet our instructors"
            variant={variant}
            sections={item.props}
          />
        )
      };
    case "Testimonials":
      return {
        tocItem: {
          id: "clients-said-about-us",
          innerText: "Clients said about us"
        },
        element: <Testimonials variant={variant} quotes={item.props} />
      };
    case "TrainingCards":
      return {
        tocItem: null,
        element: <TrainingCards variant={variant} trainings={item.props} />
      };
    case "TrainingLocations":
      return {
        tocItem: { id: "open-trainings", innerText: "Open trainings" },
        element: <TrainingLocations variant={variant} trainings={item.props} />
      };
    case "WhatWeDo":
      return {
        tocItem: null,
        element: <WhatWeDo variant={variant} sections={item.props} />
      };
    default:
      return (
        <h2>
          Fatal error while rendering <code>{item.name}</code>
          <br />
          The component does not exist yet.
        </h2>
      );
  }
};
