import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import checkboxOn from "../../assets/checkbox_on.svg";
import checkboxOff from "../../assets/checkbox_off.svg";

const Container = styled.div`
  display: flex;
  flex-direction: column;

  > * {
    width: 100%;
  }

  form {
    display: flex;
    flex-direction: column;
    @media ${props => props.theme.device.laptop} {
      margin-left: auto;
    }
  }

  label {
    font-size: 1.25rem;
    letter-spacing: 0.0625rem;
    line-height: 1.875rem;
    margin-bottom: 0.6rem;
  }

  form input,
  textarea,
  select {
    -webkit-appearance: none;
    border: 0.0625rem solid ${props => props.theme.color.border};
    background-color: inherit;
    border-radius: 0.3rem;
    color: #000;
    outline: none;
    padding: 0.8rem;
    margin-bottom: 1.5rem;
    @media ${props => props.theme.device.laptop} {
      padding: 1.25rem;
      margin-bottom: 2.5rem;
    }
  }

  textarea {
    resize: vertical;
    min-height: 11.25rem;
  }

  input:focus,
  textarea:focus {
    border: 0.0625rem solid ${props => props.theme.color.secondary};
  }

  input::placeholder,
  textarea::placeholder {
    color: ${props => props.theme.color.border};
  }

  input:invalid,
  textarea:invalid {
    box-shadow: none;
  }

  .submitted input:invalid,
  .submitted textarea:invalid {
    border-color: ${props => props.theme.color.error};
    box-shadow: none;
  }

  .submitted input:invalid:focus,
  .submitted textarea:invalid:focus {
    border-color: ${props => props.theme.color.secondary};
    box-shadow: none;
  }

  .checkbox {
    -webkit-appearance: none;
    border: none !important;
    border-radius: 0 !important;
    width: 1.4rem;
    height: 1.4rem;
    min-width: 1.4rem;
    min-height: 1.4rem;
    padding: 0;
    outline: none;
    background: url(${checkboxOff}) 0 0 no-repeat;
    :checked {
      background: url(${checkboxOn}) 0 0 no-repeat;
    }
  }
`;

const FormContainer = ({ children, ...rest }) => (
  <Container {...rest}>{children}</Container>
);

FormContainer.propTypes = {
  children: PropTypes.any
};

export default FormContainer;
