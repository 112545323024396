import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const Container = styled.a`
  border: 0.0625rem solid ${props => props.theme.color.border_light};
  border-radius: 0.3rem;
  position: relative;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  @media (hover: hover) {
    :hover {
      background-clip: padding-box;
      border-color: transparent;
    }
  }

  @media (hover: hover) {
    :hover {
      box-shadow: 0 1rem 1.7rem rgba(0, 0, 0, 0.25),
        0 .6rem .6rem rgba(0, 0, 0, 0.22);

      .hideable {
        opacity: 1;
      }
    }
  }

  .cta {
    color: ${props => props.theme.color.secondary};
    text-align: center;
    font-size: 1.25em;
  }

  .cta-button {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0.75rem;
    @media ${props => props.theme.device.tablet} {
      bottom: 1rem;
    }
  }

  .hideable {
    @media (hover: hover) {
      opacity: 0;
    }
  }
`;

export const Card = ({ children, ...rest }) => (
  <Container {...rest}>{children}</Container>
);

Card.propTpyes = {
  children: PropTypes.any
};

export default Card;
