import React from "react";
import styled from "styled-components";

import logo from "../../assets/logo-white.svg";
import illustration from "../../assets/illustrations/footer.svg";

const Container = styled.footer`
  position: relative;
  background-color: #323232;
  color: #fff;
  font-size: 1rem;
  padding: 10vw 1.5rem;
  @media ${props => props.theme.device.tablet} {
    padding: 10vw 5vw;
  }
  @media ${props => props.theme.device.laptop} {
    padding: 10vw;
  }
`;

const FooterContent = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media ${props => props.theme.device.tablet} {
    flex-direction: row;
    flex-wrap: wrap;
  }

  > div {
    margin-top: 2.5rem;
    display: flex;
    flex-direction: column;
    @media ${props => props.theme.device.tablet} {
      width: 50%;
    }
    @media ${props => props.theme.device.laptopL} {
      width: 25%;
    }
  }

  h4 {
    text-transform: uppercase;
    opacity: 0.3;
    font-size: 0.9rem;
    margin-bottom: 1rem;
    @media ${props => props.theme.device.tablet} {
      margin-bottom: 2.5rem;
    }
  }

  a {
    color: white;
    font-size: 0.8rem;
    margin-bottom: 0.7rem;
    @media (hover: hover) {
      :hover {
        font-weight: 600;
      }
    }
  }
`;

const Illustration = styled.img`
  position: absolute;
  bottom: 0;
  left: calc(3 * 25% - 13rem);
  z-index: 0;
`;

const Legal = styled.p`
  position: relative;
  color: #fff;
  opacity: 0.3;
  margin-top: 2.5rem;
  font-size: 0.8rem;
  z-index: 100;
`;

export const Footer = () => (
  <Container>
    <img className="logo" alt="logo" src={logo} />
    <FooterContent>
      <div>
        <h4>Development & Consulting</h4>
        <a href="/nodejs-development-consulting-services">
          Full-Stack Development & Node.js Consulting
        </a>
        <a href="/devops-sre-cloud-consulting-services">
          DevOps, SRE & Cloud Consulting
        </a>
        <a href="/kubernetes-consulting-training">Kubernetes Consulting</a>
        <a href="/nodejs-support">24.7 Node.js Support</a>
        <a href="/infrastructure-assessment-code-review-services">
          Infrastructure Assessment & Code Reviews
        </a>
        <a href="/trainings">Trainings & Education</a>
      </div>
      <div>
        <h4>Trainings</h4>
        <a href="/trainings">Why learn from us?</a>
        <a href="/software-development-online-training-mentorship">Online Training & Mentorship for Software Developers</a>  
        <a href="/trainings/designing-microservices-architectures">
          Designing Microservices Architectures
        </a>
        <a href="/trainings/handling-microservices-with-kubernetes">
          Handling Microservices with Kubernetes
        </a>
        <a href="/trainings/modern-front-end-with-react">
          Modern Front-End with React
        </a>
        <a href="/trainings/building-complex-apps-with-angular">
          Building Complex Apps with Angular
        </a>
        <a href="/trainings/node-js-fundamentals">Node.js Fundamentals</a>
      </div>
      <div>
        <h4>Resources & Community</h4>
        <a href="https://blog.risingstack.com">RisingStack blog</a>
        <a href="/resources">Free eBooks</a>
        <a href="https://microserviceweekly.com/">Microservices Weekly</a>
        <a href="https://medium.com/@RisingStack">Weekly Node Updates</a>
        <a href="https://www.meetup.com/nodebp/">
          Node.js Meetups by RisingStack
        </a>
      </div>
      <div>
        <h4>Other</h4>
        <a href="/we-are-hiring">Open Positions</a>
        <a href="/team">Our Team</a>
        <a href="/fullstack-js-certificate">Full-Stack JS Certificate</a>
        <a href="/community">RisingStack in the JS Community</a>
        <a href="/privacy">Privacy Policy</a>
      </div>
    </FooterContent>
    <Legal>
      © RisingStack, Inc. 2020 | RisingStack® and Trace by RisingStack® are
      registered trademarks of RisingStack, Inc.
    </Legal>
    <Illustration alt="footer illustration" src={illustration} />
  </Container>
);

export default Footer;
