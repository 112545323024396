import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import { Card, CardList, SafeImage } from "../common";

const Item = styled(Card)`
  box-sizing: border-box;
  padding: 1.5rem 1.5rem 1.5rem 1.5rem;

  @media ${props => props.theme.device.tablet} {
    padding: 2rem 2rem 2rem 2rem;
  }

  @media (hover: none) {
    padding-bottom: 3.5rem;
  }

  img {
    width: 3rem;
    height: 3rem;
    @media ${props => props.theme.device.tablet} {
      width: 4rem;
      height: 4rem;
    }
  }

  .cta-button {
    @media (hover: hover) {
      position: absolute;
      top: 1.25rem;
      right: 2.5rem;
      left: auto;
      transform: none;
    }
  }
`;

const WhatWeDo = ({ sections, variant }) => (
  <div>
    {variant != "headless" && <h2>What we do</h2>}
    <CardList steps={[{ device: "tablet", column: 2 }]} fixedCards>
      {sections.map(({ image, title, description, href }, i) => (
        <Item key={`wwd_${i}`} href={href}>
          <SafeImage
            alt={`${title} section illustration`}
            src={`illustrations/wwd/${image}`}
          />
          <h3>{title}</h3>
          <p>{description}</p>
          <h3 className="cta cta-button hideable">Learn more</h3>
        </Item>
      ))}
    </CardList>
  </div>
);

WhatWeDo.propTypes = {
  sections: PropTypes.array,
  variant: PropTypes.string
};

export default WhatWeDo;
