import React, { Component } from "react";
import styled, { keyframes } from "styled-components";
import PropTypes from "prop-types";
import { chunk } from "../../utils/chunk";
import { SafeImage } from "../common";

const LogoAnim = keyframes`
  0% {
    opacity: 0;
    margin-top: 10rem;
    margin-bottom: 0;
  }

  15% {
    opacity: 1;
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  85% {
    opacity: 1;
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  100% {
    opacity: 0;
    margin-top: 0;
    margin-bottom: 10rem;
  }
`;

const LogoAnim_M = keyframes`
  0% {
    opacity: 0;
    margin-right: 10rem;
    margin-left: 0;
  }

  15% {
    opacity: 1;
    margin-right: 4rem;
    margin-left: 4rem;
  }

  85% {
    opacity: 1;
    margin-right: 4rem;
    margin-left: 4rem;
  }

  100% {
    opacity: 0;
    margin-right: 0;
    margin-left: 10rem;
  }
`;

const Container = styled.div`
  background-color: #f7f7f7;
  text-align: center;

  h3 {
    margin-bottom: 3rem;
  }

  img {
    height: 3.75rem;
    object-fit: none;
  }
`;

const GridContainer = styled.div`
  position: relative;
  width: 100%;
  height: 20rem;
  @media ${props => props.theme.device.tablet} {
    height: 12rem;
  }
`;

const Grid = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* opacity: 0.5; */

  flex-direction: column;
  justify-content: center;
  @media ${props => props.theme.device.tablet} {
    flex-direction: row;
    justify-content: space-around;
  }

  img {
    position: relative;
    object-fit: contain;
    width: 10rem;
    margin-bottom: 2.5rem;
    @media ${props => props.theme.device.tablet} {
      margin-top: 0;
    }
  }

  #out {
    opacity: 0;
    @media ${props => props.theme.device.tablet} {
      margin-top: 11.25rem;
    }
  }

  #in {
    opacity: 0;
    animation: ${LogoAnim_M} 3s;
    @media ${props => props.theme.device.tablet} {
      animation: ${LogoAnim} 3s;
    }
  }
`;

class Clients extends Component {
  state = {
    currentOnscreen: 0,
    filteredChunks: [],
    domElements: []
  };

  applyEffect = current => {
    const { domElements } = this.state;

    if (domElements.length == 0) {
      this.setState({
        domElements: document.getElementsByClassName("animatableLogo")
      });
    }

    for (let i = 0; i < domElements.length; i++) {
      if (domElements[i].classList[1] == current.toString()) {
        domElements[i].id = "in";

        setTimeout(() => {
          domElements[i].id = "out";
        }, 3750);
      }
    }
  };

  componentDidMount() {
    const { clients } = this.props;
    const isUserFromHungary = navigator.language.includes("hu");
    const filtered = clients.filter(client => {
      if (client.region !== "hu") {
        return true;
      } else {
        if (isUserFromHungary) {
          return true;
        } else {
          return false;
        }
      }
    });
    const chunked = chunk(filtered, 3);

    this.setState({
      filteredChunks: chunked
    });

    this.applyEffect(0);

    setInterval(() => {
      if (
        this.state.currentOnscreen >=
        Math.round(this.props.clients.length / 3) - 2
      ) {
        this.setState({ currentOnscreen: 0 });
      } else {
        this.setState(state => ({
          currentOnscreen: state.currentOnscreen + 1
        }));
      }

      this.applyEffect(this.state.currentOnscreen);
    }, 2600);
  }

  render() {
    const { filteredChunks } = this.state;

    return (
      <Container className="full-width">
        <h3>Experts trust RisingStack</h3>
        <GridContainer>
          {filteredChunks.map((data, rowIndex) => (
            <Grid key={`row_${rowIndex}`}>
              {data.map(({ image }, i) => (
                <SafeImage
                  className={`animatableLogo ${rowIndex}`}
                  key={`logo_${i}`}
                  id="out"
                  alt={`client logo`}
                  style={{
                    animationDelay: `calc(${i * 250}ms)`
                  }}
                  src={`companies/${image}`}
                />
              ))}
            </Grid>
          ))}
        </GridContainer>
      </Container>
    );
  }
}

Clients.propTypes = {
  clients: PropTypes.array.isRequired
};

export default Clients;
