import React from "react";

const SafeImage = ({ src, ...rest }) => {
  let match;
  try {
    match = require(`../../assets/${src}`);
  } catch (ex) {
    // eslint-disable-next-line
    console.log(
      `WARNING: ../../assets/${src} not found - no illustration will be included`
    );
  }

  if (match) {
    return <img src={match} {...rest} />;
  } else {
    return null;
  }
};

export default SafeImage;
