import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import { Card, CardList } from "../common";

const Job = styled(Card)`
  display: flex;
  padding: 1.25rem;
  position: relative;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;

  p {
    color: #000;
    font-size: 1.25rem;
    line-height: 1.875rem;
    margin: 0;
    padding-right: 1rem;
  }
`;

const Jobs = ({ jobs }) => (
  <div className="full-width no-vertical-spacing">
    <h3 id="open-positions">Open positions</h3>
    <CardList fixedCards>
      {jobs.map((job, i) => {
        const hyphenated = job.toLowerCase().replace(/[. ]/g, "-");
        const href = `/we-are-hiring/${hyphenated}`;
        return (
          <Job key={`job_${i}`} href={href}>
            <p>{job}</p>
            <h3 className="cta">Apply</h3>
          </Job>
        );
      })}
    </CardList>
  </div>
);

Jobs.propTypes = {
  jobs: PropTypes.array
};

export default Jobs;
