import React, { Component } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Button, FormContainer } from "../common";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 3rem 1.5rem;
  @media ${(props) => props.theme.device.tablet} {
    padding: 3rem 5vw;
  }
  @media ${(props) => props.theme.device.laptop} {
    padding: 6rem 10vw;
    flex-direction: row;
  }

  > * {
    width: 100%;
    @media ${(props) => props.theme.device.laptop} {
      width: 48%;
      :nth-child(2) {
        margin-left: 4%;
      }
    }
  }

  h2 {
    margin: 0 auto 1.875rem 0;
    padding: 0;
    font-size: 2.5rem;
    @media ${(props) => props.theme.device.laptop} {
      margin: 0 auto auto 0;
    }
    @media ${(props) => props.theme.device.laptopL} {
      font-size: 3rem;
    }
  }

  #output {
    display: none;
    flex-direction: row;
    align-items: center;
    margin-top: 1rem;

    p {
      color: ${(props) => props.theme.color.secondary};
      font-size: 1rem;
      margin: 0;
    }

    img {
      width: 1rem;
      height: 1rem;
      margin-right: 0.75rem;
    }
  }
`;

class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasSubmitted: false,
    };
  }

  onSubmit = async (event) => {
    event.persist();
    event.preventDefault();

    const data = new URLSearchParams();

    for (const pair of new FormData(document.getElementById("contact-form"))) {
      data.append(pair[0], pair[1]);
    }

    data.append(
      "href",
      typeof window !== "undefined"
        ? window.location.href
        : "https://risingstack.com/"
    );

    try {
      await fetch("https://risingcontact.risingstack.com", {
        method: "POST",
        mode: "no-cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: data,
      });
      const refSubmitButton = document.getElementById("button");
      const refOutput = document.getElementById("output");

      refSubmitButton.disabled = true;
      refOutput.style.display = "flex";
    } catch (err) {
      alert("Something went wrong, please check your internet connection or contact us straight at info@risingstack.com")
    }

    return false;
  };

  render() {
    const { hasSubmitted } = this.state;
    return (
      <FormContainer>
        <Container>
          <h2>Let's get in touch</h2>
          <form
            name="contact-form"
            id="contact-form"
            className={`contact-form ${hasSubmitted ? "submitted" : undefined}`}
            onSubmit={this.onSubmit}
          >
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              placeholder="e.g. Elon Musk"
              required
            />
            <label htmlFor="email">Email address</label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="e.g. elon@tesla.com"
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
              required
            />
            <label htmlFor="phone">Phone number (optional)</label>
            <input
              type="tel"
              id="phone"
              name="phone"
              placeholder="e.g. +1 (800) 613-8840"
            />
            <label htmlFor="message">Message</label>
            <textarea
              id="message"
              name="message"
              placeholder="Help me RisingStack, you are my only hope..."
              minLength="80"
              required
            />
            {/* Commented out because we don't use file upload yet */}
            {/* <label htmlFor="file">(Optional) Add an attachement</label>
            <input
              id="file"
              type="file"
              name="file"
              placeholer="Drop your file here..."
            /> */}
            <Button
              as="button"
              id="button"
              type="submit"
              onClick={() => {
                this.setState({ hasSubmitted: true });
              }}
            >
              Send
            </Button>
            <div id="output">
              <img src="https://risingstack-blog.s3-eu-west-1.amazonaws.com/static/illustrations/check.svg" />
              <p id="thank-you">
                Thanks for reaching out to us! We'll reply via email within
                24 hours
              </p>
            </div>
          </form>
        </Container>
      </FormContainer>
    );
  }
}

ContactForm.defaultProps = {
  slug: "/",
};

ContactForm.propTypes = {
  slug: PropTypes.string,
};

export default ContactForm;
