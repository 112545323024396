import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import { FormContainer } from "../common";

const Container = styled(FormContainer)`
  width: 100%;
  padding: 2rem 0;
  @media ${props => props.theme.device.tablet} {
    padding: 5rem 0;
  }

  .mc-field-group {
    display: flex;
    flex-direction: column;
    width: 100%;
    * {
      width: 100%;
    }
  }

  .checkbox {
    margin: 0 1rem 0 0;
    @media ${props => props.theme.device.tablet} {
      margin: 0 1rem 0 2rem;
    }
  }

  .download-container {
    display: flex;
    flex-direction: column;
    @media ${props => props.theme.device.tablet} {
      flex-direction: row;
    }
    > input {
      flex: 2;
    }
    > div {
      flex: 3;
    }
  }

  .download-button {
    padding: 1rem;
    border-radius: 1.875rem;
    background-color: ${props => props.theme.color.secondary};
    text-align: center;
    color: #fff;
    /* font-weight: 300; */
    opacity: 0.9;
    display: inline-block;
    font-size: 1rem;
    font-weight: 600;
    border: 0;

    :hover {
      opacity: 1;
    }
  }

  .checkbox-container {
    display: flex;
  }
`;

const EbookDownload = ({ urlParams }) => (
  <Container>
    <div id="mc_embed_signup">
      <form
        action={`https://risingstack.us9.list-manage.com/subscribe/post${urlParams}`}
        method="post"
        id="mc-embedded-subscribe-form"
        name="mc-embedded-subscribe-form"
        className="validate"
        target="_blank"
      >
        <div id="mc_embed_signup_scroll">
          <div className="mc-field-group">
            <label htmlFor="mce-EMAIL">Email Address</label>
            <input
              type="email"
              name="EMAIL"
              id="mce-EMAIL"
              placeholder="Type your email address here..."
              required
            />
          </div>
          <div className="mc-field-group">
            <label htmlFor="mce-FNAME">First Name</label>
            <input
              type="text"
              name="FNAME"
              id="mce-FNAME"
              placeholder="Type your first name here..."
              required
            />
          </div>
          <div className="mc-field-group">
            <label htmlFor="mce-MMERGE2">
              The Number of Node.js Engineers at your Company
            </label>
            <select name="MMERGE2" id="mce-MMERGE2" required>
              <option value=""></option>
              <option value="1 to 5 Node Engineers">
                1 to 5 Node Engineers
              </option>
              <option value="6 to 10 Node Engineers">
                6 to 10 Node Engineers
              </option>
              <option value="11 to 25 Node Engineers">
                11 to 25 Node Engineers
              </option>
              <option value="26 to 50 Node Engineers">
                26 to 50 Node Engineers
              </option>
              <option value="51 to 100 Node Engineers">
                51 to 100 Node Engineers
              </option>
              <option value="We don't have Node.js Engineers">
                We don't have Node.js Engineers
              </option>
            </select>
          </div>
          <div id="mce-responses">
            <div id="mce-error-response" style={{ display: "none" }}></div>
            <div id="mce-success-response" style={{ display: "none" }}></div>
          </div>{" "}
          {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups */}
          <div style={{ position: "absolute", left: -5000 }} aria-hidden="true">
            <input
              type="text"
              name="b_510d6f81b948a39e0d9c32ec3_6aca04fb1c"
              tabIndex="-1"
              value=""
            />
          </div>
          <div className="download-container">
            <input
              type="submit"
              value="Download"
              name="subscribe"
              id="mc-embedded-subscribe"
              className="download-button"
            />
            <div className="checkbox-container">
              <input
                type="checkbox"
                className="checkbox"
                value="1"
                name="group[49][1]"
                id="mce-group[49]-49-0"
                required
              />
              <p>
                To download, first accept RisingStack's{" "}
                <a href="https://risingstack.com/privacy">Privacy Policy.</a>
              </p>
            </div>
          </div>
        </div>
      </form>
    </div>
  </Container>
);

EbookDownload.propTypes = {
  urlParams: PropTypes.string.isRequired
};

export default EbookDownload;
