import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media ${props => props.theme.device.tablet} {
    flex-flow: row wrap;
  }

  &.fix {
    > * {
      margin-bottom: 1.25rem;
    }

    /* 2 col */
    @media ${({ theme, steps, length, disableAutoSizing }) => {
        const corresp =
          (!disableAutoSizing || length >= 2) &&
          steps.find(item => item.column == 2);
        return theme.device[corresp ? corresp.device : "null"];
      }} {
      justify-content: space-between;

      > * {
        width: 48%;
        margin: 0 0 4% 0;
        margin-bottom: 4% !important;
      }

      > *:nth-of-type(2n + 2) {
        margin-left: 4%;
      }
    }

    /* 3 col */
    @media ${({ theme, steps, length, disableAutoSizing }) => {
        const corresp =
          (!disableAutoSizing || length >= 3) &&
          steps.find(item => item.column == 3);
        return theme.device[corresp ? corresp.device : "null"];
      }} {
      justify-content: flex-start;

      > * {
        width: 32%;
        margin: 0 0 2% 0;
        margin-bottom: 2% !important;
      }

      > *:nth-of-type(2n + 2) {
        margin-left: 0;
      }

      > *:nth-of-type(3n + 2) {
        margin: 0 2%;
      }
    }

    /* 4 col */
    @media ${({ theme, steps, length, disableAutoSizing }) => {
        const corresp =
          (!disableAutoSizing || length >= 4) &&
          steps.find(item => item.column == 4);
        return theme.device[corresp ? corresp.device : "null"];
      }} {
      justify-content: flex-start;

      > *:nth-of-type(3n + 2) {
        margin: 0 2% 5% 0;
      }

      > * {
        width: 23.5%;
        margin: 0 2% 5% 0;
        margin-bottom: 5% !important;
      }


      > *:nth-of-type(4n) {
        margin-right: 0;
      }
    }
  }
`;

export const CardList = ({
  children,
  steps,
  disableAutoSizing,
  fixedCards,
  ...rest
}) => (
  <Container
    className={fixedCards ? "fix" : undefined}
    steps={steps}
    fixedCards={fixedCards}
    disableAutoSizing={disableAutoSizing}
    length={children.length}
    {...rest}
  >
    {children}
  </Container>
);
CardList.propTpyes = {
  children: PropTypes.any,
  steps: PropTypes.array,
  fixedCards: PropTypes.bool,
  disableAutoSizing: PropTypes.bool
};

CardList.defaultProps = {
  children: <h1>CardList children is missing!</h1>,
  isUneven: false,
  fixedCards: false,
  disableAutoSizing: false,
  steps: [{ device: "laptopL", column: 3 }, { device: "tablet", column: 2 }]
};

export default CardList;
