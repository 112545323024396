import React, { Component } from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { StickyContainer } from "react-sticky";
import { StaticQuery, graphql } from "gatsby";
import styled from "styled-components";

import Footer from "./Footer";
import Jumbotron from "./Jumbotron";
import NavBar from "./NavBar";
import Sidebar from "./Sidebar";
import CookieConsent from "./CookieConsent";
import ContactForm from "../meta/ContactForm";
import GlobalStyle from "../../styles/globalStyle";

const Container = styled.main`
  padding: 0 1.5rem;
  @media ${props => props.theme.device.tablet} {
    padding: 0 5vw;
  }
  @media ${props => props.theme.device.laptop} {
    padding: 0 10vw;
  }

  .two-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media ${props => props.theme.device.laptop} {
      flex-direction: row;
      justify-content: space-between;
    }
    > * :first-child {
      margin-right: 4%;
    }
    > * {
      width: 100%;
      @media ${props => props.theme.device.laptop} {
        width: 48%;
      }
    }
  }

  .two-column-heading {
    width: 100%;
    @media ${props => props.theme.device.laptop} {
      width: 48%;
    }
  }
`;

class Layout extends Component {
  state = {
    hasConsented: true,
    isHamburgerOpen: false,
    isSidebarOpen: false,
    jumbotronHeight: 0
  };

  componentDidMount() {
    this.setState({
      hasConsented: this.getCookie("rs_cookie_consent_accepted") == "true"
    });
    setTimeout(() => {
      try {
        this.setState({
          jumbotronHeight: document.getElementsByTagName("header")[0]
            .clientHeight
        });
      } catch {
        // eslint-disable-next-line
        console.warn("WARNING: no element with ID found");
      }
    }, 10);
  }

  getCookie = name => {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  };

  toggleHamburger = () => {
    if (this.state.isSidebarOpen) {
      this.toggleSidebar();
    } else {
      this.setState(state => {
        return { isHamburgerOpen: !state.isHamburgerOpen };
      });
    }
  };

  toggleSidebar = () => {
    const { jumbotronHeight, isSidebarOpen } = this.state;
    if (!isSidebarOpen && window.scrollY < jumbotronHeight) {
      window.scrollTo({
        top: jumbotronHeight,
        left: 0,
        behavior: "smooth"
      });
    }
    this.setState({ isSidebarOpen: !isSidebarOpen });
  };

  render() {
    const {
      data,
      children,
      bodyClass,
      title,
      subtitle,
      jumbotronVariant,
      illustrations,
      bgColor,
      selected,
      meta,
      toc
    } = this.props;
    const {
      hasConsented,
      jumbotronHeight,
      isHamburgerOpen,
      isSidebarOpen
    } = this.state;

    let canonical = meta.canonical;

    if (canonical.substr(-1) == "/") {
      canonical = canonical.substring(0, canonical.length - 1);
    }

    canonical = canonical.replace(/risingsite.risingstack.com/g, "risingstack.com");

    const site = data.allGhostSettings.edges[0].node;

    return (
      <>
        <Helmet>
          <html lang={site.lang} />
          <title>{meta.title}</title>
          <link rel="canonical" href={canonical} />
          <meta name="description" content={meta.description} />
          <meta property="og:url" content={canonical} />
          <meta property="og:title" content={meta.title} />
          <meta property="og:description" content={meta.description} />
          <meta property="og:site_name" content={site.name} />
          <meta property="og:locale" content="en_US" />
          {meta.featureImage != null && (
            <meta property="og:image" content={meta.featureImage} />
          )}
          <meta property="twitter:title" content={meta.title} />
          <meta property="twitter:description" content={meta.description} />
          <meta property="twitter:card" content="summary_large_image" />
          <meta property="twitter:site" content="@RisingStack" />
          <meta property="twitter:creator" content="@RisingStack" />
          <meta property="twitter:domain" content="risingstack.com" />
          <meta
            name="google-site-verification"
            content="1VZwYFsfiVb7eRGn7Hx4CQN2PHLUmbYZnlJOXrBaCPA"
          />
          <meta
            name="google-site-verification"
            content="9aNnXl2Gjjk464Pu8muDfA6oKb2NcYKjrbDVp_Cs8gc"
          />
          <body className={bodyClass} />
        </Helmet>

        <GlobalStyle />

        <NavBar
          bgColor={bgColor}
          selected={selected}
          isOpen={isHamburgerOpen}
          toggle={this.toggleHamburger}
          showOuterX={isSidebarOpen}
        />

        <div className="viewport">
          <div className="viewport-top">
            <Jumbotron
              title={title}
              subtitle={subtitle}
              jumbotronVariant={jumbotronVariant}
              illustrations={illustrations}
              bgColor={bgColor}
            />
            <StickyContainer>
              {toc && !isHamburgerOpen && (
                <Sidebar
                  contents={toc}
                  topOffset={jumbotronHeight - 64}
                  isOpen={isSidebarOpen}
                  toggle={this.toggleSidebar}
                />
              )}
              <Container>{children}</Container>
            </StickyContainer>
          </div>

          <div id="form">
            <ContactForm />
          </div>
          <div className="viewport-bottom">
            <Footer />
          </div>
        </div>

        {!hasConsented && (
          <CookieConsent
            removeHandler={() => this.setState({ hasConsented: true })}
          />
        )}
      </>
    );
  }
}

const LayoutSettingsQuery = props => (
  <StaticQuery
    query={graphql`
      query GhostSettings {
        allGhostSettings {
          edges {
            node {
              ...GhostSettingsFields
            }
          }
        }
      }
    `}
    render={data => <Layout data={data} {...props} />}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  bodyClass: PropTypes.string,
  data: PropTypes.shape({
    allGhostSettings: PropTypes.object.isRequired
  }).isRequired,
  title: PropTypes.arrayOf(PropTypes.string),
  subtitle: PropTypes.string,
  jumbotronVariant: PropTypes.string,
  bgColor: PropTypes.string,
  illustrations: PropTypes.any,
  meta: PropTypes.object,
  toc: PropTypes.array
};

export default LayoutSettingsQuery;
