import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import { CardList, SafeImage } from "../common";

const Container = styled.div`
  padding: 3rem 1.5rem;
  @media ${props => props.theme.device.tablet} {
    padding: 3rem 5vw;
  }
  @media ${props => props.theme.device.laptop} {
    padding: 3rem 10vw;
  }
`;

const Member = styled.div`
  h3 {
    margin: 1rem 0 0.6rem 0;
    @media ${props => props.theme.device.tablet} {
      margin: 1.5rem 0 0.6rem 0;
    }
  }
`;

const Team = ({ heading, sections, variant }) => (
  <Container className={`full-width content-gray ${variant}`}>
    <h2
      style={{
        display: variant == "noheading" ? "none" : "block"
      }}
      id="meet-our-instructors"
      className="baseline-center-right"
    >
      {heading}
    </h2>
    <CardList
      steps={[
        { device: "laptopL", column: 4 },
        { device: "laptop", column: 3 },
        { device: "tablet", column: 2 }
      ]}
      disableAutoSizing
      fixedCards
    >
      {sections.map(({ image, name, description, blogposts }, i) => {
        return (
          <Member key={`teammember_${i}`}>
            <SafeImage src={`team/${image}`} alt={`${name} profile picture`} />
            <h3>{name}</h3>
            <p>{description}</p>
            {blogposts && (
              <ul>
                {blogposts.map(({ url, title }, index) => (
                  <li key={`blogpost_${name}_${index}`}>
                    <a href={url}>{title}</a>
                  </li>
                ))}
              </ul>
            )}
          </Member>
        );
      })}
    </CardList>
  </Container>
);

Team.propTypes = {
  sections: PropTypes.array
};

export default Team;
