import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import { Card, CardList, SafeImage } from "../common";
import pin from "../../images/pin.svg";

const Container = styled(CardList)`
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  &.home {
    margin: 0;
    width: 100%;
    padding: 1.5rem 0;
    @media ${props => props.theme.device.tablet} {
      padding: 2.5rem 0;
    }
  }
`;

const Training = styled(Card)`
  display: flex;
  flex-direction: column;
  width: 100%;
  color: #000;
  background-color: #fff;
  position: relative;
  margin-bottom: 10%;

  @media ${props => props.theme.device.laptop} {
    width: 48%;
    margin-bottom: 0;
    :first-of-type {
      margin-right: 4%;
    }
  }

  * {
    margin: 0;
  }

  h3 {
    @media ${props => props.theme.device.laptop} {
      width: 60%;
    }
  }

  .city-img {
    height: 17.5vh;
    width: calc(100% + 2px);
    object-fit: cover;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
  }

  .description {
    margin: 0 0 2rem 0;
  }

  #sold-out {
    position: absolute;
    background-color: #f37748;
    border-radius: 0.3rem;
    padding: 0.5rem 1rem;
    top: -0.75rem;
    left: -0.75rem;

    p {
      color: #fff;
      font-size: 1rem;
    }
  }

  .content-container {
    padding: 1.5rem 1.5rem 2.5rem 1.5rem;
    @media ${props => props.theme.device.tablet} {
      padding: 2.5rem 2.5rem 3.5rem 2.5rem;
    }
  }

  .cta-button {
    bottom: 1.5rem;
    @media ${props => props.theme.device.tablet} {
      bottom: 2rem;
    }
  }
`;

const TopBar = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  @media ${props => props.theme.device.laptop} {
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 0.6rem;
  }
`;

const Location = styled.div`
  display: flex;
  align-items: center;
  margin: 0.5rem 0 0.5rem 0;
  @media ${props => props.theme.device.laptop} {
    margin: 1rem 0 auto 0;
  }
  img {
    width: 1rem;
    margin-right: 0.6rem;
  }
  h3 {
    font-size: 1rem;
  }
  @media ${props => props.theme.device.laptopL} {
    margin: 0 0 auto 0;
    img {
      width: 1.25rem;
    }
    h3 {
      font-size: 1.25rem;
    }
  }
`;

const OpenTrainings = ({ trainings, variant }) => (
  <Container className={`full-width ${variant}`}>
    {trainings.map(
      ({ title, description, location, cover, href, soldOut }, i) => {
        return (
          <Training key={`ot_${i}`} href={href}>
            <SafeImage
              className="city-img"
              alt={`${location} city landscape`}
              src={`open-trainings/${cover}`}
            />
            <div className="content-container">
              <TopBar>
                <h3>{title}</h3>
                <Location>
                  <img alt="pin icon" src={pin} />
                  <h3>{location}</h3>
                </Location>
              </TopBar>
              <p className="description">{description}</p>
              <h3 className="cta cta-button hideable">Learn more</h3>
            </div>
            {soldOut && (
              <div id="sold-out">
                <p>Sold out</p>
              </div>
            )}
          </Training>
        );
      }
    )}
  </Container>
);

OpenTrainings.propTypes = {
  trainings: PropTypes.array
};

export default OpenTrainings;
