import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const Container = styled.header`
  color: #fff;
  position: relative;
  background: ${props => props.bgColor || props.theme.color.primary};
  display: flex;
  align-items: center;
  padding: 5.5rem 1.5rem 2.5rem 1.5rem;
  @media ${props => props.theme.device.tablet} {
    padding: calc(5vw + 4rem) 5vw 5vw 5vw;
  }
  @media ${props => props.theme.device.laptop} {
    padding: calc(7.5vw + 4rem) 10vw 7.5vw 10vw;
  }

  h1 {
    padding: 0;
  }

  p {
    color: #fff;
  }

  &.basic {
    padding: 5.5rem 1.5rem 1.5rem 1.5rem;
    @media ${props => props.theme.device.tablet} {
      padding: calc(5vw + 4rem) 5vw 5vw 5vw;
    }
    @media ${props => props.theme.device.laptop} {
      padding: calc(5vw + 4rem) 10vw 5vw 10vw;
    }
  }

  &.basic,
  &.training,
  &.jobs {
    text-align: left;
  }

  &.basic,
  &.jobs {
    .contents {
      max-width: 100%;
      @media ${props => props.theme.device.tablet} {
        max-width: 70%;
      }
    }
  }

  &.jobs {
    .subtitle {
      width: 100% !important;
    }
  }

  &.home {
    text-align: center;

    * {
      margin: 0 auto;
    }
    h1 {
      font-size: 2.5rem;
      @media ${props => props.theme.device.tablet} {
        font-size: 3.5rem;
      }
      @media ${props => props.theme.device.laptop} {
        font-size: 4rem;
      }
      @media ${props => props.theme.device.laptopL} {
        font-size: 5rem;
      }
    }
    img {
      opacity: 0;
      height: 50%;
      @media ${props => props.theme.device.tablet} {
        opacity: 1;
      }
      @media ${props => props.theme.device.laptop} {
        height: 60%;
      }
      @media ${props => props.theme.device.laptopL} {
        height: 70%;
      }
    }
    .subtitle {
      width: 100%;
      @media ${props => props.theme.device.tablet} {
        width: 60%;
      }
    }
    #illustrations {
      left: 0;
    }
  }

  .contents {
    z-index: 1;
  }

  .subtitle {
    width: 90%;
    @media ${props => props.theme.device.laptop} {
      font-size: 1.25rem;
      line-height: 2rem;
    }
    margin-bottom: 0 !important;
  }

  img {
    position: absolute;
    max-height: 50%;
    opacity: 0;
    @media ${props => props.theme.device.tablet} {
      opacity: 1;
      max-height: 80%;
      max-width: 17.5%;
      object-fit: contain;
      object-position: 0 100%;
    }
  }

  .smaller-illustration {
    height: 30% !important;
    @media ${props => props.theme.device.tablet} {
      height: 50% !important;
    }
  }

  .training-illustration {
    height: 20% !important;
    @media ${props => props.theme.device.tablet} {
      height: 40% !important;
    }
  }

  &.home {
    img {
      height: 40% !important;
      @media ${props => props.theme.device.laptop} {
        height: 50% !important;
      }
      @media ${props => props.theme.device.laptopL} {
        height: 60% !important;
      }
    }
  }

  .training-illustration {
    border-radius: 10%;
  }

  .training-illustration,
  .smaller-illustration {
    top: 50%;
    bottom: 50%;
    transform: translateY(-50%);
    right: 10%;
    display: none;
    @media ${props => props.theme.device.tablet} {
      display: block;
    }
  }

  .title {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    @media ${props => props.theme.device.laptopL} {
      margin-bottom: 2rem;
    }
  }
`;

export const Jumbotron = ({
  title,
  subtitle,
  jumbotronVariant,
  illustrations,
  bgColor
}) => (
  <Container bgColor={bgColor} className={jumbotronVariant}>
    <div className="contents">
      <div className="content">
        {/* {jumbotronVariant == "training" && illustrations} */}
        <h1
          dangerouslySetInnerHTML={{ __html: title.replace(/-/g, "‑") }}
          className="title"
        />
      </div>
      <p className="subtitle">{subtitle}</p>
    </div>
    {/* {jumbotronVariant !== "training" && ( */}
    {illustrations}
    {/* )} */}
  </Container>
);

Jumbotron.propTypes = {
  title: PropTypes.arrayOf(PropTypes.string),
  subtitle: PropTypes.string,
  jumbotronVariant: PropTypes.string,
  bgColor: PropTypes.string,
  illustrations: PropTypes.any
};

export default Jumbotron;
