import React, { Component } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import scrollToElement from "scroll-to-element";
import { Link } from "gatsby";

// Logo
import logo from "../../assets/logo-white.svg";

// Hamburger
import close from "../../assets/close.svg";
import menu from "../../assets/menu.svg";

// What we do
import FullStack from "../../assets/illustrations/wwd/1-fullstack.svg";
import DevOps from "../../assets/illustrations/wwd/2-devops.svg";
import Kubernetes from "../../assets/illustrations/wwd/3-kubernetes.svg";
import NodeSupport from "../../assets/illustrations/wwd/4-nodesupport.svg";
import Review from "../../assets/illustrations/wwd/5-review.svg";
import Trainings from "../../assets/illustrations/wwd/6-trainings.svg";

// Trainings
import AllTrainings from "../../assets/illustrations/trainings/all.svg";
import TrainingMicroservices from "../../assets/illustrations/trainings/designing-microservices-architectures-training.svg";
import TrainingKubernetes from "../../assets/illustrations/trainings/handling-microservices-with-kubernetes-training.svg";
import TrainingReact from "../../assets/illustrations/trainings/modern-front-end-with-react-training.svg";
import TrainingAngular from "../../assets/illustrations/trainings/building-complex-apps-with-angular-training.svg";
import TrainingNode from "../../assets/illustrations/trainings/node-js-fundamentals-training.svg";
import TrainingOnline from "../../assets/illustrations/trainings/online-training-mentorship-services-for-software-developers.svg";

// Components
import Button from "./Button";

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${(props) => props.bgColor || props.theme.color.primary};
  z-index: 100;
  padding: 0 1.5rem;
  @media ${(props) => props.theme.device.tablet} {
    padding: 0 5vw;
  }
  @media ${(props) => props.theme.device.laptop} {
    padding: 0 10vw;
  }
  .mobile-cta {
    @media ${(props) => props.theme.device.tablet} {
      display: none;
    }
  }
`;

const Logo = styled.img`
  margin: auto 1rem auto 0;
  width: 8rem;
  outline: none;
  border: 0;
`;

const Item = styled.div`
  position: relative;
  text-align: center;
  padding: 2.5% 0;
  margin: 2.5% 0;

  @media ${(props) => props.theme.device.tablet} {
    position: initial;
  }
  @media ${(props) => props.theme.device.laptop} {
    position: relative;
  }

  p {
    color: #000;
  }

  .nav-item {
    cursor: pointer;
    color: #fff;
    padding: 2rem 0;
  }

  .dev-con-shorten:before {
    content: "Development & Consulting";
    @media ${(props) => props.theme.device.tablet} {
      content: "Development";
    }
    @media ${(props) => props.theme.device.laptop} {
      content: "Development & Consulting";
    }
  }

  .cta {
    margin-left: 0;
    @media ${(props) => props.theme.device.tablet} {
      margin-left: 2rem;
    }
  }

  #selected {
    font-weight: 600;
  }

  @media ${(props) => props.theme.device.tablet} {
    margin: auto 0;
    padding: 0;
    display: inline-block;
  }
`;

const Arrow = styled.div`
  border-style: solid;
  border-width: 0 1rem 1rem 1rem;
  border-color: transparent transparent #fff transparent;
  margin: 0 auto 0 auto;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 2.9rem;
  z-index: 999999;
  @media ${(props) => props.theme.device.tablet} {
    top: 0.2rem;
  }
`;

const Hoverbox = styled.div`
  z-index: 100;

  display: ${(props) => (props.isOpen ? "block" : "none")};
  opacity: ${(props) => (props.isOpen ? 1 : 0)};
  visibility: ${(props) => (props.isOpen ? "visible" : "hidden")};

  @media ${(props) => props.theme.device.tablet} {
    display: none;
    opacity: 0;
    visibility: hidden;
    position: relative;
    .nav-item:hover + &,
    :hover {
      display: block;
      opacity: 1;
      visibility: visible;
    }
  }
`;

const HoverboxContent = styled.div`
  z-index: 100;
  margin-top: 1rem;
  display: flex;
  border-radius: 0.5rem;
  background-color: #fff;
  padding: 2rem 2rem 0.5rem 2rem;
  box-shadow: 0 0 1rem 0 rgba(135, 135, 135, 0.6);
  flex-direction: column;

  @media ${(props) => props.theme.device.tablet} {
    flex-direction: row;
    opacity: 1;
    visibility: visible;
    padding: 3rem;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 0;
    top: 3.75rem;
  }
  @media ${(props) => props.theme.device.laptop} {
    position: absolute;
    top: 1rem;
  }
`;

const HamburgerMenu = styled.div`
  display: ${(props) => (props.isOpen ? "block" : "none")};
  background: ${(props) => props.bgColor || props.theme.color.primary};
  position: fixed;
  overflow: scroll;
  height: calc(100vh - 4rem);
  top: 4rem;
  right: 0px;
  left: 0px;
  bottom: 0px;
  align-items: center;

  @media ${(props) => props.theme.device.tablet} {
    position: initial;
    display: block;
    overflow: visible;
    top: 0;
    height: 4rem;
  }

  @media ${(props) => props.theme.device.laptop} {
    position: relative;
  }

  .nav-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin: 5% 0 auto 0;
    @media ${(props) => props.theme.device.tablet} {
      flex-direction: row;
      align-items: center;
      height: 100%;
      margin: auto 0;
    }
  }
`;

const HamburgerButton = styled.button`
  display: block;
  --webkit-display: none;
  color: white;
  background-color: inherit;
  border: 0;
  outline: none;
  font-size: 20px;
  @media ${(props) => props.theme.device.tablet} {
    display: none;
  }
  height: 1.5rem;
  width: 1.5rem;
  img {
    height: 1.5rem;
    width: 1.5rem;
  }
`;

const BoxItem = styled.a`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-content: center;
  margin-bottom: 1.8rem;
  cursor: pointer;
  color: #000;
  text-align: left;

  :last-of-type {
    @media ${(props) => props.theme.device.tablet} {
      margin: 0;
    }
  }

  img {
    width: 2.5rem;
    height: 2.5rem;
    margin-right: 1rem;
  }

  .bigger-icon {
    border-radius: 0.3rem;
    width: 3rem;
    height: 3rem;
  }

  p {
    margin: 0;
  }

  @media ${(props) => props.theme.device.tablet} {
    width: 18rem;
    margin-right: 2rem;
  }
`;

export class NavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDevOpen: false,
      isTrainingOpen: false,
    };
  }

  componentDidMount() {
    this.refs.hamburgerRef.addEventListener(
      "touchmove",
      (e) => {
        const { isDevOpen, isTrainingOpen } = this.state;

        if (!isDevOpen && !isTrainingOpen) {
          e.preventDefault();
        }
      },
      false
    );
  }

  render() {
    const { isDevOpen, isTrainingOpen } = this.state;
    const { isOpen, toggle, selected, bgColor, showOuterX } = this.props;

    return (
      <Container
        bgColor={bgColor}
        onScroll={(e) => e.stopPropagation()}
        onScrollCapture={(e) => e.stopPropagation()}
        onTouchMove={(e) => e.preventDefault()}
      >
        <Link style={{ display: "flex", alignItems: "center" }} to="/">
          <Logo src={logo} alt="RisingStack logo" />
        </Link>

        <Button
          className="nav-item cta mobile-cta"
          onClick={() => scrollToElement(`#form`, { offset: -100 })}
          style={{
            margin: "0 5% 0 auto",
            padding: "1.5%",
            maxWidth: "7rem",
            fontSize: "0.8rem",
          }}
        >
          Contact us
        </Button>

        <HamburgerButton onClick={toggle}>
          {isOpen || showOuterX ? <img src={close} /> : <img src={menu} />}
        </HamburgerButton>

        <HamburgerMenu isOpen={isOpen} bgColor={bgColor} ref="hamburgerRef">
          <div className="nav-container">
            <Item
              isOpen={isDevOpen}
              onClick={() =>
                this.setState((state) => {
                  return { isDevOpen: !state.isDevOpen };
                })
              }
            >
              <a
                id={selected === "Development" ? "selected" : ""}
                className="nav-item dev-con-shorten"
              />
              <Hoverbox isOpen={isOpen & isDevOpen}>
                <Arrow />
                <HoverboxContent>
                  <div>
                    <BoxItem href="/nodejs-development-consulting-services">
                      <img
                        alt="Full-stack Development & Node.js Consulting illustration"
                        src={FullStack}
                      />
                      <p>Full-stack Development & Node.js Consulting</p>
                    </BoxItem>
                    <BoxItem href="/devops-sre-cloud-consulting-services">
                      <img
                        alt="DevOps, SRE & Cloud Consulting illustration"
                        src={DevOps}
                      />
                      <p>DevOps, SRE & Cloud Consulting</p>
                    </BoxItem>
                    <BoxItem href="/kubernetes-consulting-training">
                      <img
                        alt="Kubernetes Consulting illustration"
                        src={Kubernetes}
                      />
                      <p>Kubernetes Consulting</p>
                    </BoxItem>
                  </div>
                  <div>
                    <BoxItem href="/nodejs-support">
                      <img
                        alt="24/7 Node.js support illustration"
                        src={NodeSupport}
                      />
                      <p>24/7 Node.js support</p>
                    </BoxItem>
                    <BoxItem href="/infrastructure-assessment-code-review-services">
                      <img
                        alt="Infrastructure Assessment & Code Reviews illustration"
                        src={Review}
                      />
                      <p>Infrastructure Assessment & Code Reviews</p>
                    </BoxItem>
                    <BoxItem href="/trainings">
                      <img alt="Trainings illustration" src={Trainings} />
                      <p>Trainings & Education</p>
                    </BoxItem>
                  </div>
                </HoverboxContent>
              </Hoverbox>
            </Item>
            <Item
              isOpen={isTrainingOpen}
              onClick={() =>
                this.setState((state) => {
                  return { isTrainingOpen: !state.isTrainingOpen };
                })
              }
            >
              <a
                id={selected === "Trainings" ? "selected" : ""}
                className="nav-item"
                style={{ margin: "0 2rem" }}
              >
                Trainings
              </a>
              <Hoverbox isOpen={isOpen & isTrainingOpen}>
                <Arrow />
                <HoverboxContent>
                  <div>
                    <BoxItem href="/trainings">
                      <img
                        alt="Trainings illustration"
                        className="bigger-icon"
                        src={AllTrainings}
                      />
                      <p>Why learn from us?</p>
                    </BoxItem>
                    <BoxItem href="/trainings/designing-microservices-architectures">
                      <img
                        alt="Designing Microservices Architectures training illustration"
                        className="bigger-icon"
                        src={TrainingMicroservices}
                      />
                      <p>Designing Microservices Architectures</p>
                    </BoxItem>
                    <BoxItem
                      alt="Handling Microservices with Kubernetes training illustration"
                      href="/trainings/handling-microservices-with-kubernetes"
                    >
                      <img className="bigger-icon" src={TrainingKubernetes} />
                      <p>Handling Microservices with Kubernetes</p>
                    </BoxItem>
                    <BoxItem href="/trainings/modern-front-end-with-react">
                      <img
                        alt="Modern Front-End with React training illustration"
                        className="bigger-icon"
                        src={TrainingReact}
                      />
                      <p>Modern Front-End with React</p>
                    </BoxItem>
                  </div>
                  <div>
                    <BoxItem href="/software-development-online-training-mentorship">
                      <img
                        alt="Online Training & Mentorship Services illustration"
                        className="bigger-icon"
                        src={TrainingOnline}
                      />
                      <p>Online Training & Mentorship Services</p>
                    </BoxItem>
                    <BoxItem href="/trainings/building-complex-apps-with-angular">
                      <img
                        alt="Building Complex Apps with Angular training illustration"
                        className="bigger-icon"
                        src={TrainingAngular}
                      />
                      <p>Building Complex Apps with Angular</p>
                    </BoxItem>
                    <BoxItem href="/trainings/node-js-fundamentals">
                      <img
                        alt="Node.js Fundamentals training illustration"
                        className="bigger-icon"
                        src={TrainingNode}
                      />
                      <p>Node.js Fundamentals</p>
                    </BoxItem>
                  </div>
                </HoverboxContent>
              </Hoverbox>
            </Item>
            <Item>
              <a className="nav-item" href="https://blog.risingstack.com">
                Blog
              </a>
            </Item>
            <Item>
              <Button
                className="nav-item cta"
                onClick={() => scrollToElement(`#form`, { offset: -100 })}
                style={{
                  padding: "0.4rem 0",
                  width: "10rem",
                }}
              >
                Contact us
              </Button>
            </Item>
          </div>
        </HamburgerMenu>
      </Container>
    );
  }
}

NavBar.propTypes = {
  selected: PropTypes.string,
  bgColor: PropTypes.string,
};

export default NavBar;
