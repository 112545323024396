import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
  :root {
    font-family: 'WebMontserrat', sans-serif;
    font-display: fallback;
  }

  * {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    font-size: 1rem;
    vertical-align: baseline;
    transition: 0.1s all ease-in-out;
    -webkit-tap-highlight-color: transparent;
  }

  body {
    line-height: 1;
  }

  img {
    max-width: 100%;
  }

  html {
    box-sizing: border-box;
    /* scroll-behavior: smooth; */

    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }
  a {
    background-color: transparent;
  }

  a:active,
  a:hover {
    outline: 0;
  }

  b,
  strong {
    font-weight: 600;
  }

  img {
    border: 0;
  }

  code,
  kbd,
  pre,
  samp {
    font-family: monospace, monospace;
    font-size: 1.25rem;
  }

  html {
    overflow-x: hidden;
    overflow-y: scroll;
    font-size: 100%;
  }

  body {
    overflow-x: hidden;
    color: ${props => props.theme.color.base};
    font-size: 1.5rem;
    line-height: 1.6rem;
    font-weight: 400;
    font-style: normal;
    text-rendering: optimizeLegibility;
    background: #fff;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -moz-font-feature-settings: "liga" on;
    font-feature-settings: "liga" on;
  }

  p,
  ul,
  ol,
  dl,
  blockquote {
    margin: 0 0 1.5rem 0;
  }

  blockquote {
    color: ${props => props.theme.color.primary};
    padding: 0 2.5rem;
    font-size: 1.25rem;
    letter-spacing: 0.0625rem;
    line-height: 1.875rem;
  }

  ol,
  ul {
    color: ${props => props.theme.color.border};
    padding-left: 1.3rem;
    padding-right: 1.5rem;
  }

  ol ol,
  ul ul,
  ul ol,
  ol ul {
    margin: 0.5rem 0 1rem;
    a:hover {
      text-decoration: underline !important;
    }
  }

  li {
    margin: 0.5rem 0;
    padding-left: 0.3rem;
    line-height: 1.6rem;
  }

  a {
    color: ${props => props.theme.color.secondary};
    text-decoration: none;
  }

  a:hover {
    text-decoration: none;
  }


  h1,
  h2,
  h3 {
    color: ${props => props.theme.color.base};
    line-height: 1.15;
    text-rendering: optimizeLegibility;
  }

  h1 {
    margin: 0 0 0.5rem 0;
    color: #fff;
    font-size: 2rem;
    font-weight: 600;
    @media ${props => props.theme.device.tablet} {
      font-size: 3rem;
    }
    @media ${props => props.theme.device.laptop} {
      font-size: 3.5rem;
    }
    @media ${props => props.theme.device.laptopL} {
      font-size: 4.5rem;
    }
  }

  h2 {
    margin: 3rem 0;
    color: ${props => props.theme.color.primary};
    font-weight: 300;
    letter-spacing: 0.0625rem;
    max-width: 90%;
    font-size: 2rem;
    line-height: 2.5rem;
    @media ${props => props.theme.device.tablet} {
      max-width: 80%;
    }
    @media ${props => props.theme.device.laptop} {
      font-size: 3rem;
      line-height: 3.5rem;
      max-width: 60%;
    }
    @media ${props => props.theme.device.laptopL} {
      margin: 0 0 3rem 0;
      max-width: 40%;
    }
  }

  h3 {
    margin: 1.25rem 0;
    font-weight: 400;
    letter-spacing: 0.0625rem;
    font-size: 1.25rem;
    line-height: 1.875rem;
    /* color: ${props => props.theme.color.primary};
    font-size: 3rem;
    line-height: 3.5rem; */
  }

  p {
    font-size: 1rem;
    letter-spacing: 0.03rem;
    line-height: 1.5rem;
    color: ${props => props.theme.color.border};
  }

  ::selection {
    color: white;
    background: ${props => props.theme.color.secondary};
  }

  .full-width {
    margin-left: -1.5rem;
    width: 100vw;
    padding: 3rem 1.5rem;
    @media ${props => props.theme.device.tablet} {
      margin-left: -5vw;
      padding: 3rem 5vw;
    }
    @media ${props => props.theme.device.laptop} {
      margin-left: -10vw;
      padding: 6rem 10vw;
    }
  }

  .no-spacing {
    margin: 0;
    padding: 0;
  }

  .no-vertical-spacing {
    margin: 0 -1.5rem;
    padding: 0 1.5rem;
    @media ${props => props.theme.device.tablet} {
      margin: 0 -5vw;
      padding: 0 5vw;
    }
    @media ${props => props.theme.device.laptop} {
      margin: 0 -10vw;
      padding: 0 10vw;
    }
  }

  .no-top-spacing {
    margin-top: 0 !important;
  }

  .container {
    display: flex;
    flex-direction: column;
    background-color: ${props => props.theme.color.background};
    padding: 3rem 1.5rem;
    margin: 0 -1.5rem;
    @media ${props => props.theme.device.tablet} {
      margin: 0 -10vw;
      padding: 3rem 10vw;
    }
    @media ${props => props.theme.device.laptop} {
      padding: 10% 25vw;
    }
    @media ${props => props.theme.device.laptopL} {
      padding: 10% 30vw;
    }

    .full-width {
      margin: 0 -1.5rem;
      @media ${props => props.theme.device.tablet} {
        margin: 0 -5vw;
      }
      @media ${props => props.theme.device.laptop} {
        margin: 0 -25vw;
      }
      @media ${props => props.theme.device.laptopL} {
        margin: 0 -30vw;
      }
    }

    .full-width-container {
      margin: 0 -1.5rem;
      padding: 0 1.5rem;
      @media ${props => props.theme.device.tablet} {
        margin: 0 -10vw;
        padding: 0 10vw;
      }
      @media ${props => props.theme.device.laptop} {
        margin: 0 -25vw;
        padding: 0 25vw;
      }
      @media ${props => props.theme.device.laptopL} {
        margin: 0 -30vw;
        padding: 0 30vw;
      }
    }
  }

  .content-gray {
    background-color: ${props => props.theme.color.background};
  }

  .baseline-center-right {
    margin-left: 0;
    @media ${props => props.theme.device.laptop} {
      margin-left: calc(50vw - 10vw);
    }
  }

  .no-spacing {
    margin-top: 0;
  }
`;
