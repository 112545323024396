import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import { Button, CardList, SafeImage } from "../common";

const Container = styled.div`
  padding-bottom: 0 !important;
`;

const Book = styled.a`
  width: 100%;
  display: flex;
  flex-direction: column;
  -webkit-display: none;
  outline: none;
  padding-bottom: 2rem;

  .cover {
    width: 50%;
    @media ${props => props.theme.device.laptop} {
      width: 100%;
    }
  }

  .title {
    margin: 1.25rem 0 0.6rem 0;
    @media ${props => props.theme.device.tablet} {
      margin: 2rem 0 1rem 0;
    }
  }

  .outline {
    margin-top: auto;
  }
`;

const EbookSimiliar = ({ books, variant }) => (
  <Container
    className={`full-width content-gray ${variant} ${
      variant == "noheading" ? "no-vertical-spacing" : undefined
    }`}
  >
    <h2
      style={{
        marginTop: 0,
        display: variant == "noheading" ? "none" : "block"
      }}
      className="baseline-center-right"
      id="similiar-ebooks"
    >
      Similiar eBooks
    </h2>
    <CardList
      steps={[
        { device: "laptopL", column: 4 },
        { device: "laptop", column: 3 },
        { device: "tablet", column: 2 }
      ]}
      disableAutoSizing
      fixedCards
    >
      {books.map(({ image, name, description, href }, i) => {
        return (
          <Book key={`book_${i}`} href={`/resources/${href}`}>
            <SafeImage
              alt="book cover"
              className="cover"
              src={`resources/covers/${image}`}
            />
            <h3 className="title">{name}</h3>
            <p className="description">{description}</p>
            <Button as="div" variant="outline">
              Download
            </Button>
          </Book>
        );
      })}
    </CardList>
  </Container>
);

EbookSimiliar.propTypes = {
  books: PropTypes.array,
  variant: PropTypes.string
};

export default EbookSimiliar;
