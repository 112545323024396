import React, { Component } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import { SafeImage } from "../common";

const Container = styled.div`
  border-color: ${props => props.theme.color.border_light};
  border-width: 0.0625rem 0;
  border-style: solid;
  overflow-x: hidden;
  position: relative;

  ::-webkit-scrollbar {
    height: 0 !important;
  }

  &.boxed {
    margin: 1.875rem -5rem;
    padding: 4rem 5rem;
    border: 0.0625rem solid ${props => props.theme.color.border_light};
    background-color: white;
    border-radius: 0.3rem;
    h2 {
      padding: 0;
      margin: 0 0 2.5rem 0;
      max-width: 100%;
    }
  }
`;

const QuoteContainer = styled.div`
  overflow-x: hidden;
  position: relative;
  display: flex;

  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
`;

const Quote = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 100%;
  text-align: left;
  position: relative;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin-right: 20px;
  margin-bottom: auto;
  overflow-x: hidden;
  position: relative;

  @media ${props => props.theme.device.laptop} {
    flex-direction: row;
    margin-bottom: 0;
  }

  .image {
    width: 50vw;
    height: 50vw;
    margin: 0 auto 3rem 0;
    @media ${props => props.theme.device.tablet} {
      width: 15rem;
      height: 15rem;
    }
    @media ${props => props.theme.device.laptop} {
      margin: 0 7.5% 0 auto;
    }
    display: none;
    @media ${props => props.theme.device.laptop} {
      display: block;
    }
    &.boxed {
      width: 100%;
      height: 23rem;
      margin: 0 10% 1.25rem 10%;
      display: none !important;
    }
  }

  .mini-image {
    display: block;
    min-width: 4rem;
    min-height: 4rem;
    width: 4rem;
    height: 4rem;
    border-radius: 2rem;
    margin-right: 1rem;
    @media ${props => props.theme.device.laptop} {
      display: none;
    }
    &.boxed {
      display: block !important;
    }
  }

  #person {
    color: #000;
    font-size: 1.25rem;
  }

  .text-container {
    flex-direction: column;
    overflow: -moz-hidden-unscrollable;
    @media ${props => props.theme.device.laptop} {
      width: 50%;
    }
    &.boxed {
      width: 100%;
    }
  }

  .person-container {
    display: flex;
    flex-direction: row;
    &.boxed {
      align-items: center;
    }
    p {
      margin-bottom: 0;
    }
  }

  &.boxed {
    flex-direction: column;
    margin-bottom: auto;
  }
`;

const IndicatorContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 1.25rem;
  @media ${props => props.theme.device.tablet} {
    margin-top: 3rem;
  }
  &.boxed {
    margin: 5% auto 0 0;
  }
`;

const Indicator = styled.div`
  width: 1.25rem;
  height: 0.6rem;
  margin-right: 0.6rem;
  border-radius: 0.3rem;
  cursor: pointer;
  background-color: #d8d8d8;
  &.active {
    background-color: ${props => props.theme.color.secondary};
  }
`;

class Testimonials extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slides: this.props.quotes.length,
      containerRef: React.createRef(),
      currentSlide: 0,
      isMouseIn: false,
      latestByUser: false,
      runningAnimation: false
    };

    this.interval = setInterval(() => {
      this.autoAnimate();
    }, 6000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  autoAnimate = () => {
    const { isMouseIn, latestByUser, runningAnimation } = this.state;
    if (!isMouseIn && !latestByUser && !runningAnimation) {
      this.switchSlide(null, true);
    }
    this.setState({ latestByUser: false });
  };

  onMouseHover = () => {
    this.setState(state => ({ isMouseIn: !state.isMouseIn }));
  };

  switchSlide = (index, isAuto) => {
    if (this.state.runningAnimation) {
      return;
    }
    this.setState({ runningAnimation: true });
    const { slides, currentSlide, containerRef } = this.state;
    if (isAuto && !containerRef.current) {
      return;
    }

    let slide;

    if (index !== null) {
      slide = index;
      this.setState({ latestByUser: true });
    } else {
      slide = currentSlide;
      if (currentSlide === slides - 1) {
        slide = 0;
      } else {
        slide += 1;
      }
    }
    this.setState({ currentSlide: slide });
    this.scrollTo(
      containerRef.current,
      slide * (containerRef.current.clientWidth + 19.5),
      1000
    );
  };

  scrollTo = (element, to, duration) => {
    let start = element.scrollLeft,
      change = to - start,
      currentTime = 0,
      increment = 20;

    setTimeout(() => {
      this.setState({ runningAnimation: false });
    }, duration);

    const animateScroll = function() {
      currentTime += increment;
      var val = Math.easeInOutQuad(currentTime, start, change, duration);
      element.scrollLeft = val;
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    };

    animateScroll();
  };

  render() {
    const { quotes, variant } = this.props;
    const { currentSlide, containerRef } = this.state;
    return (
      <Container
        className={`content-gray ${variant} ${variant !== "boxed" &&
          "full-width"}`}
      >
        <h2
          id="clients-said-about-us"
          className={
            variant !== "boxed"
              ? "no-top-spacing baseline-center-right"
              : undefined
          }
        >
          Clients said about us
        </h2>
        <QuoteContainer
          ref={containerRef}
          onMouseEnter={this.onMouseHover}
          onMouseLeave={this.onMouseHover}
        >
          {quotes.map(({ image, testimonial, name, role }, i) => (
            <Quote className={variant} key={`quote_${i}`}>
              <SafeImage
                src={`clients/testimonials/${image}`}
                alt={`${name} profile picture`}
                className={`image ${variant}`}
              />
              <div className={`text-container ${variant}`}>
                <p>{testimonial}</p>
                <div className={`person-container ${variant}`}>
                  <SafeImage
                    src={`clients/testimonials/${image}`}
                    alt={`${name} profile picture`}
                    className={`mini-image ${variant}`}
                  />
                  <p id="person">
                    {name},<br />
                    {role}
                  </p>
                </div>
              </div>
            </Quote>
          ))}
        </QuoteContainer>
        <IndicatorContainer
          className={`${
            variant !== "boxed" ? "baseline-center-right" : undefined
          } ${variant}`}
        >
          {quotes.map((_, i) => (
            <Indicator
              key={`indicator_${i}`}
              className={i == currentSlide ? "active" : undefined}
              onClick={() => this.switchSlide(i, false)}
            />
          ))}
        </IndicatorContainer>
      </Container>
    );
  }
}

Testimonials.defaultProps = {
  quotes: []
};

Testimonials.propTypes = {
  quotes: PropTypes.array,
  variant: PropTypes.string
};

Math.easeInOutQuad = (t, b, c, d) => {
  t /= d / 2;
  if (t < 1) return (c / 2) * t * t + b;
  t--;
  return (-c / 2) * (t * (t - 2) - 1) + b;
};

export default Testimonials;
