import React, { Component } from "react";
import styled from "styled-components";
import { Sticky } from "react-sticky";
import Scrollspy from "react-scrollspy";
import scrollToElement from "scroll-to-element";

const Container = styled.div`
  position: absolute;
  background-color: ${props => props.theme.color.secondary};
  z-index: 90;
  transition: 0.3s ease-in-out;
  transition-property: width, height;
  width: 100vw;
  height: ${props => (props.isSidebarOpen ? "100vh" : "2.5rem")};
  margin-top: ${props => (props.isSticky ? "4rem" : 0)};
  padding: ${props => (props.isSidebarOpen ? "2rem 0" : "0")};
  overflow: hidden;
  justify-content: center;
  align-items: center;

  @media ${props => props.theme.device.laptop} {
    width: ${props => (props.isSidebarOpen ? "30vw" : "3rem")} !important;
    height: 100%;
    margin-top: 0;
    padding-top: ${props => (props.isSticky ? "6rem" : "2rem")};
    justify-content: flex-start;
    align-items: flex-start;
  }

  @media ${props => props.theme.device.laptopL} {
    width: ${props => (props.isSidebarOpen ? "25vw" : "3rem")} !important;
  }

  .itemContainer {
    display: flex;
    height: ${props => (props.isSidebarOpen ? "100%" : "2.5rem")};
    flex-direction: ${props => (props.isSidebarOpen ? "column" : "row")};
    justify-content: ${props =>
      props.isSidebarOpen ? "flex-start" : "center"};
    align-items: ${props => (props.isSidebarOpen ? "flex-start" : "center")};
    padding: 0 1.25rem !important;

    @media ${props => props.theme.device.laptop} {
      flex-direction: column;
      height: 100%;
      justify-content: flex-start;
    }
  }

  button {
    width: 3rem;
    height: 3rem;
    border: 1px solid white;
    font-size: 1.2rem;
    border-radius: 1.5rem;
    --webkit-display: none;
    background-color: inherit;
    margin: 1rem auto 0 auto;
    color: white;
    outline: none;

    @media ${props => props.theme.device.laptop} {
      display: none;
      visibility: hidden;
    }
  }
`;

const Item = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 2rem;
  height: 2rem;
  margin: 0.75rem 0;
  padding-right: 2rem;
  cursor: pointer;
  @media ${props => props.theme.device.laptop} {
    width: ${props => (props.isSidebarOpen ? "25vw" : "0.75rem")};
    padding-right: 0;
    margin-right: auto;
  }
  @media ${props => props.theme.device.laptopL} {
    width: ${props => (props.isSidebarOpen ? "22.5vw" : "0.75rem")};
  }
  :last-of-type {
    padding-right: 0;
  }
  a {
    color: white;
    margin: 0 1.25rem;
  }
  &.active {
    * {
      opacity: 1;
    }
    font-weight: 600;
    transition: 0.1s opacity font-weight ease-in-out;
  }
  @media (hover: hover) {
    &:hover {
      * {
        opacity: 1;
      }
      font-weight: 600;
      transition: 0.1s opacity font-weight ease-in-out;
    }
  }
`;

const Dot = styled.div`
  height: 0.5rem;
  width: 0.5rem;
  min-height: 0.5rem;
  min-width: 0.5rem;
  border-radius: 0.25rem;
  opacity: 0.5;
  background-color: white;
`;

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisitingFromMobile: false,
      canTouch: false
    };
  }

  componentDidMount = () => {
    // console.log(this.refs)
    this.refs.tocRef.addEventListener(
      "touchmove",
      e => {
        e.preventDefault();
      },
      false
    );
    this.setState({ isVisitingFromMobile: window.innerWidth < 1024 });
    this.setState({
      canTouch: "ontouchstart" in window || navigator.msMaxTouchPoints > 0
    });
    window.addEventListener("resize", () => {
      this.setState({ isVisitingFromMobile: window.innerWidth < 1024 });
    });
  };

  onClick = id => {
    scrollToElement(`#${id}`, { offset: -150 });
    if (this.state.isVisitingFromMobile) {
      this.props.toggle();
    }
  };

  render() {
    const { isVisitingFromMobile, canTouch } = this.state;
    const { contents, topOffset, isOpen, toggle } = this.props;
    // TODO: find a way to uncomment this without breaking the Gatsby build
    // const convertRemToPixels = rem => {
    //   return (
    //     rem * parseFloat(getComputedStyle(document.documentElement).fontSize)
    //   );
    // };

    return (
      <div ref="tocRef">
        <Sticky disableCompensation topOffset={-64}>
          {({ style, isSticky }) => (
            <Container
              style={style}
              isSidebarOpen={isOpen}
              onMouseEnter={
                isVisitingFromMobile ? (!canTouch ? undefined : toggle) : toggle
              }
              onMouseLeave={isVisitingFromMobile ? undefined : toggle}
              onClick={isOpen ? undefined : toggle}
              isSticky={isSticky}
              topOffset={topOffset}
            >
              <Scrollspy
                items={contents.map(item => item.id)}
                className="itemContainer"
                currentClassName="active"
              >
                {contents.map(({ id, innerText }) => (
                  <Item
                    key={`toc_${id}`}
                    className="item"
                    isSidebarOpen={isOpen}
                  >
                    <Dot />
                    <a
                      className="content-link"
                      style={{
                        display: isOpen ? "inline-block" : "none"
                      }}
                      onClick={() => this.onClick(id)}
                      // href={`#${id}`}
                    >
                      {innerText}
                    </a>
                  </Item>
                ))}
                {isOpen && <button onClick={toggle}>X</button>}
              </Scrollspy>
            </Container>
          )}
        </Sticky>
      </div>
    );
  }
}

export default Sidebar;
