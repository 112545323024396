import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const Container = styled.a`
  padding: 1rem .5rem;
  border-radius: 1.875rem;
  background-color: ${props => props.theme.color.secondary};
  text-align: center;
  color: #fff;
  display: inline-block;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;

  &.outline {
    background-color: inherit;
    border: 1px solid ${props => props.theme.color.secondary};
    color: ${props => props.theme.color.secondary};
    width: 100%;
    font-size: 20px;
    :hover {
      background-color: ${props => props.theme.color.secondary};
      color: white;
    }
  }

  width: 100%;
  @media ${props => props.theme.device.tablet} {
    width: 50vw;
  }
  @media ${props => props.theme.device.laptop} {
    width: 20vw;
  }
`;

export const Button = ({ children, variant, ...rest }) => (
  <Container className={`${variant}`} {...rest}>{children}</Container>
);

Button.propTpyes = {
  children: PropTypes.any,
  variant: PropTypes.string
};

Button.defaultProps = {
  children: "",
  variant: "default"
}

export default Button;
