import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import { Card, CardList } from "../common";
import pin from "../../images/pin.svg";

const Training = styled(Card)`
  display: flex;
  padding: 1.25rem;
  position: relative;
  justify-content: space-between;
  align-items: center;

  p {
    color: #000;
    font-size: 1.25rem;
    line-height: 1.875rem;
    margin: 0;
  }

  .contents {
    width: 100%;
  }

  #sold-out {
    position: absolute;
    background-color: #f37748;
    border-radius: 0.3rem;
    padding: 0.3rem 0.6rem;
    top: -0.75rem;
    right: -0.75rem;

    p {
      color: #fff;
      font-size: 1rem;
    }
  }

  #scheduled {
    font-size: 1.25rem;
    color: ${props => props.theme.color.border};
  }
`;

const TrainingLocations = ({ trainings }) => (
  <div>
    <h3 style={{ margin: "6rem 0 1.25rem 0" }} id="open-trainings">Open trainings</h3>
    <CardList
      steps={[
        { device: "laptopL", column: 3 },
        { device: "tablet", column: 2 }
      ]}
      fixedCards
    >
      {trainings.map(({ href, location, scheduled, soldOut }, i) => (
        <Training key={`training_${i}`} href={!soldOut && href}>
          <div className="contents">
            <div style={{ display: "flex" }}>
              <img alt={"pin icon"} src={pin} style={{ margin: "0 .6rem 1.25rem 0" }} />
              <p>{location}</p>
            </div>
            <p id="scheduled">{scheduled}</p>
          </div>
          {soldOut ? (
            <div id="sold-out">
              <p>Sold out</p>
            </div>
          ) : (
            <h3 className="cta hideable">Apply</h3>
          )}
        </Training>
      ))}
    </CardList>
  </div>
);

TrainingLocations.propTypes = {
  trainings: PropTypes.array
};

export default TrainingLocations;
